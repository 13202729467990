<mat-toolbar color="primary">
  <a *ngIf="isRoot(); else backButton" routerLink="/" class="hidden-a">
    <img class="logo" src="/assets/fort.svg"/>
  </a>
  <ng-template #backButton>
    <button routerLink="/" mat-icon-button class="logo">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </ng-template>

  <span>{{title}}</span>
  <span class="spacer"></span>
  <button mat-icon-button [routerLink]="['settings']">
    <mat-icon>settings</mat-icon>
  </button>
  <button mat-icon-button *ngIf="darkTheme; else lightBlock" (click)="setDarkTheme(false)">
    <mat-icon>light_mode</mat-icon>
  </button>
  <ng-template #lightBlock>
    <button mat-icon-button (click)="setDarkTheme(true)">
      <mat-icon>dark_mode</mat-icon>
    </button>
  </ng-template>
</mat-toolbar>
