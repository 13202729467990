<button *ngIf="buttonType == 'flat'"
        mat-flat-button
        (click)="toggleSubscription()"
        [color]="subscribed ? '' : 'accent'"
        [disabled]="disabled">
  <mat-icon>{{subscribed? 'notifications_on' : 'notifications'}}</mat-icon>
  <span>{{(subscribed ? 'Unsubscribe' : 'Subscribe') | uppercase}}</span>
</button>
<button *ngIf="buttonType == 'fab'"
        mat-fab extended
        (click)="toggleSubscription()"
        [color]="subscribed ? '' : 'accent'"
        [disabled]="disabled">
  <mat-icon>{{subscribed? 'notifications_on' : 'notifications'}}</mat-icon>
  {{(subscribed ? 'Unsubscribe' : 'Subscribe') | uppercase}}
</button>

<button *ngIf="buttonType == 'icon'"
        mat-icon-button
        (click)="toggleSubscription()"
        [class.background-light]="isSolid && !dark"
        [class.background-dark]="isSolid && dark"
        [disabled]="disabled">
  <mat-icon>{{subscribed ? 'notifications_on' : 'notifications'}}</mat-icon>
</button>
