<table mat-table [dataSource]="dataSource" [trackBy]="trackByTime">
  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef>Start</th>
    <td class="mat-small" mat-cell *matCellDef="let element">
      {{formatStartDate(element)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef>End</th>
    <td class="mat-small" mat-cell *matCellDef="let element">
      {{formatEndDate(element)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th  mat-header-cell *matHeaderCellDef>Duration</th>
    <td class="mat-small" mat-cell *matCellDef="let element" >
      {{ duration(element) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td class="math-small" mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.status() == 'running'">sync</mat-icon>
      <mat-icon *ngIf="element.status() == 'done'">done</mat-icon>
      <mat-icon *ngIf="element.status() == 'ungraceful'" color='warn'>error</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="cols"></tr>
  <tr mat-row *matRowDef="let row; columns: cols;"></tr>

</table>
<mat-paginator [pageSize]="5"
               [pageSizeOptions]="[5,10,25,100]"
               showFirstLastButtons />
