<ng-template [ngIf]="state == 'loading'">
  <div class="flex vertical-center">
    <mat-spinner/>
  </div>
</ng-template>

<div *ngIf="state == 'offline'" class="flex vertical-center">
  <div class="big-icon">
    <mat-icon>link_off</mat-icon>
  </div>
  <div class="mat-headline-3">{{zoneName()}} is offline</div>
</div>

<div *ngIf="state == 'success'" class="flex">
  <div class="grid border">
    <section class="tracking" *ngIf="zone.tracking">
      <div class="mat-headline-6">
        Tracking
        <span class="mat-subtitle-1">
          • started {{formatSince(zone.tracking)}} ago
        </span>
      </div>
      <app-tracking-player *ngIf="zone.tracking.stream"
                           [src]="zone.tracking.stream.stream_URL"
                           [thumbnail]="zone.tracking.stream.thumbnail_URL" />
      <app-tracking-status [tracking]="zone.tracking"
                           [now]="now"/>

    </section>


    <section class="climate" *ngIf="zone.climate">
      <div class="mat-headline-6">Climate <span class="mat-subtitle-1"> • started {{formatSince(zone.climate)}} ago</span></div>
      <app-climate-status class="status" [climate]="zone.climate" [now]="now"/>
      <app-climate-chart  [host]="zone.host" [zone]="zone.name"/>
    </section>

    <section class="alarms" *ngIf="zone.identifier != '.'">
      <div class="alarms-actions">
        <div class="mat-headline-6">Alarms</div>
        <app-zone-notification-button [target]="zone.identifier"
                                      buttonType="flat"/>
      </div>
      <app-alarms-reports [alarms]="zone.alarms" [now]="now"/>
    </section>

  </div>
</div>
