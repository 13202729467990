<mat-accordion>
  <mat-expansion-panel *ngFor="let a of alarms; trackBy identifyReport">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon [color]="colorForReport(a)"
                  [matBadge]="a.count()" class="disabled-animations">
          {{iconForReport(a)}}
        </mat-icon>
        <span>since {{since(a)}}</span>
      </mat-panel-title>
      <mat-panel-description>
        {{a.description}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-event-report [events]="a.events.slice().reverse()"/>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
