<table mat-table [dataSource]="dataSource()" [trackBy]="trackByIndex">

  <ng-container matColumnDef="dim">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">
      {{element.dim}}
    </td>
  </ng-container>

  <ng-container matColumnDef="current">
    <th mat-header-cell *matHeaderCellDef>Current</th>
    <td mat-cell *matCellDef="let element">
      {{element.current}}
    </td>
  </ng-container>

  <ng-container matColumnDef="target">
    <th mat-header-cell *matHeaderCellDef>{{formatTarget()}}</th>
    <td mat-cell *matCellDef="let element">
      {{element.target}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

</table>
