<div class="flex">
  <mat-list class="border">
    <mat-list-item>
      <span matListItemTitle>Olympus Backend Version</span>
      <span matListItemLine>{{backend_version}}</span>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle>Olympus Frontend Version</span>
      <span matListItemLine>{{frontend_version}}</span>
    </mat-list-item>
  </mat-list>
</div>
