<div class="content">
  <div class="overlay" [class.dark-theme]="darkTheme">
    <button mat-icon-button
            [routerLink]="['host',zone.host,'zone',zone.name]"
            fragment="alarms"
            *ngIf="showEmergencies() || showWarnings()"
            [color]="showEmergencies() ? 'warn': 'accent'">
      <mat-icon>{{showEmergencies() ? 'error' : 'warning'}}</mat-icon>
    </button>
    <app-zone-notification-button
      buttonType="icon"
      [target]="zone.identifier"
      solid />
  </div>
  <a [routerLink]="['host',zone.host,'zone',zone.name]">
    <mat-card appearance="outlined">
      <img *ngIf="zone.streamThumbnailURL();else noStreamBlock" mat-card-image [src]="zone.streamThumbnailURL()" alt="Preview of tracking on {{zone.identifier}}"/>
      <ng-template #noStreamBlock>
        <div class="missing-stream" href="/">
          <span><mat-icon>link_off</mat-icon></span>
        </div>
      </ng-template>

      <mat-card-title>{{zone.identifier}}</mat-card-title>

      <mat-card-footer>
        <div class="status-container">
          <div class="status" *ngIf="zone.tracking">
            <span>Disk Usage:</span>
            <span>{{usedFraction()}}</span>
          </div>
          <app-bounded-progress-bar *ngIf="zone.tracking"
                                    [value]="zone.tracking.total_bytes - zone.tracking.free_bytes"
                                    [maximum]="zone.tracking.total_bytes"/>
        </div>

        <div class="status-container">
          <div class="status" *ngIf="zone.climate">
            <span>Temperature:</span>
            <span>{{zone.climate.temperature | number: '1.1-1'}}°C</span>
          </div>
          <app-bounded-progress-bar *ngIf="zone.climate && zone.climate.temperature_bounds"
                                    [value]="zone.climate.temperature"
                                    [minimum]="zone.climate.temperature_bounds.minimum"
                                    [maximum]="zone.climate.temperature_bounds.maximum"/>
        </div>

        <div class="status-container">
          <div class="status" *ngIf="zone.climate">
            <span>Humidity:</span>
            <span>{{zone.climate.humidity | number: '1.1-1'}} % R.H.</span>
          </div>
          <app-bounded-progress-bar *ngIf="zone.climate && zone.climate.humidity_bounds"
                                    [value]="zone.climate.humidity"
                                    [minimum]="zone.climate.humidity_bounds.minimum"
                                    [maximum]="zone.climate.humidity_bounds.maximum"/>
        </div>

      </mat-card-footer>
    </mat-card>
  </a>
</div>
